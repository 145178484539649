<template>
  <div>
    <div class="sm:hidden">
      <select
        aria-label="Choisir une tab"
        class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
        @change="nav"
      >
        <option v-for="tab in tabs" :key="tab.id" :value="tab">
          {{ tab.label }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex px-2 justify-center">
          <router-link
            v-for="tab in tabs"
            :key="tab.id"
            :to="tab.to"
            :exact="tab.exact"
            class="whitespace-no-wrap mx-4 first:ml-0 py-4 px-4 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
          >
            {{ tab.label }}
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.is-active,
.router-link-active {
  @apply border-blue-500 text-blue-600;
}
</style>

<script>
export default {
  name: 'NavTabs',
  props: {
    tabs: { type: Array, default: () => [] }
  },
  methods: {
    nav(event) {
      const tab = event.target.value;
      console.log(tab);
      this.$router.push(tab.to);
    }
  }
};
</script>
