<template>
  <main>
    <div class="bg-white sticky top-0 z-10">
      <sqr-page-header :title="relation.name" class="p-4"> </sqr-page-header>
      <nav-tabs :tabs="tabs" />
    </div>
    <router-view></router-view>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import NavTabs from '@/components/NavTabs';

export default {
  name: 'Relations',
  components: { NavTabs },
  props: {
    bid: String,
    rid: String
  },
  computed: {
    ...mapGetters('relation', ['loading', 'loadError']),
    ...mapGetters('relation', { relation: 'record' }),
    ...mapGetters('brokers', ['broker']),
    tabs() {
      const bid = this.bid;
      const rid = this.rid;
      return [
        {
          id: 'general',
          label: 'Général',
          to: { name: 'relation-general', param: { bid, rid } },
          exact: true
        },
        {
          id: 'polices',
          label: 'Polices',
          to: { name: 'relation-policies', param: { bid, rid } }
        },
        {
          id: 'documents',
          label: 'Documents',
          to: { name: 'relation-documents', param: { bid, rid } }
        }
      ];
    }
  },
  mounted() {
    this.reload();
  },
  metaInfo() {
    return {
      title: this.title
    };
  },
  methods: {
    ...mapMutations('relation', ['fieldSet']),
    ...mapActions('relation', ['sub', 'unsub', 'update', 'save']),

    reload() {
      this.sub({ path: ['brokers', this.bid], id: this.rid });
    },

    archiveAndBack(archive) {
      this.archive({ archive }).then(() => this.$router.back());
    },

    tagAdd(tag) {
      let tags = this.relation.tags || [];
      if (tags.indexOf(tag) >= 0) return;
      tags.push(tag);
      this.fieldSet({ field: 'tags', value: [...tags] });
      this.save();
    },
    tagRemove(tag) {
      let tags = this.relation.tags.filter(t => t !== tag);
      this.fieldSet({ field: 'tags', value: tags });
      this.save();
    }
  }
};
</script>

<style scoped>
.row:nth-child(even) {
  background-color: #eeeeee;
}

.row:hover {
  background-color: #e3ccff;
}

.coord {
  padding-left: 0;
}

.archived {
  text-decoration: line-through;
}
</style>
